
import { computed, defineComponent, PropType, ref } from 'vue';
import TrendingChart from '@/components/charts/TrendingChart.vue';
import { Student } from '@/models/Student';
import { ChartData } from '@/models/ChartData';
import ZeroLandingComponent from '@/components/ZeroLandingComponent.vue';
import Class from '../../models/Class';
export default defineComponent({
  components: {
    TrendingChart,
    ZeroLandingComponent
  },
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    chartData: {
      type: Object as PropType<ChartData>,
      required: false
    },
    classData: {
      type: Object as PropType<Class>,
      required: true
    }
  },
  setup(props) {
    let latestRidesRemaining = ref(-1);
    if (props.student.ridesRemaining.length != 0) {
      latestRidesRemaining = ref<number>(
        props.student.ridesRemaining[props.student.ridesRemaining.length - 1]
      );
    }
    const relativeRankCourse = computed(() => {
      if (props.chartData && props.chartData.rank_course) {
        return props.chartData.rank_course.map((data) => {
          if (data.id === props.student.id) {
            return {
              class_rank: '',
              follow_on_course: ''
            };
          }
          let placement;
          if (!data.class_rank.classRank) {
            placement = 'Unknown';
            return {
              placement,
              follow_on_course: data.follow_on_course
            };
          }
          if (data.class_rank.classRank == 1) {
            placement = data.class_rank.classRank + 'st';
          } else if (data.class_rank.classRank == 2) {
            placement = data.class_rank.classRank + 'nd';
          } else if (data.class_rank.classRank == 3) {
            placement = data.class_rank.classRank + 'rd';
          } else {
            placement = data.class_rank.classRank + 'th';
          }
          return {
            placement,
            follow_on_course: data.follow_on_course
          };
        });
      }
      return [];
    });
    const trendDirection = computed(() => {
      const probability = props.student.graduationProbability;
      if (probability < 0.7) {
        return 'down';
      } else if (probability < 0.9) {
        return 'right';
      } else if (probability >= 0.9) {
        return 'up';
      } else {
        return 'none';
      }
    });
    const courseLabel = props.classData.courseId
      ? props.classData.courseId
      : 'No course assigned';
    return {
      courseLabel,
      relativeRankCourse,
      trendDirection,
      latestRidesRemaining
    };
  }
});
