
import { defineComponent, PropType } from 'vue';
import StudentMetricCard from '@/components/student/StudentMetricCardComponent.vue';
import StudentTrendingCardComponent from '@/components/student/StudentTrendingCardComponent.vue';
import ClassMetricsTableComponent from '@/components/class/ClassMetricsTableComponent.vue';
import { Student } from '@/models';
import { useRouter } from 'vue-router';
import Routes from '@/router/Routes';
import { ClassMetric } from '../../models/ClassMetrics';
import { decelerateDataSet } from '@/components/charts/example-data/decelerateDataSet';
import { accelerateDataSet } from '@/components/charts/example-data/accelerateDataSet';
import ZeroLandingComponent from '@/components/ZeroLandingComponent.vue';
import Class from '../../models/Class';

export default defineComponent({
  components: {
    ClassMetricsTableComponent,
    StudentMetricCard,
    StudentTrendingCardComponent,
    ZeroLandingComponent
  },
  props: {
    students: {
      type: Array as PropType<Array<Student>>,
      required: true
    },
    classMetrics: {
      type: Array as PropType<Array<ClassMetric>>,
      required: true
    },
    classes: {
      type: Array as PropType<Array<Class>>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();

    function handleStudentSelected(student: Student) {
      router.push({
        name: Routes.STUDENT_PROGRESS,
        params: { id: String(student.id) }
      });
    }

    function getStudentClass(student: Student): Class {
      const studentClass = props.classes.find(
        (classData: Class) => classData.id === student.classId
      );
      return studentClass || new Class();
    }

    return {
      getStudentClass,
      accelerateDataSet,
      decelerateDataSet,
      handleStudentSelected
    };
  }
});
