
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: String
    }
  },
  setup(props) {
    const textColor = computed(() => {
      switch (props.data) {
        case 'fair':
          return 'text-yellow-500';
        default:
          return 'text-green-500';
      }
    });
    return {
      textColor
    };
  }
});
