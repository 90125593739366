
import { Component, computed, defineComponent, PropType } from 'vue';
import ClassMetricTableCellDnifComponent from './table/ClassMetricTableCellDnifComponent.vue';
import ClassMetricTableCellGradeComponent from './table/ClassMetricTableCellGradeComponent.vue';
import ClassMetricTableCellStatusComponent from './table/ClassMetricTableCellStatusComponent.vue';
import ClassMetricTableCellDefaultComponent from './table/ClassMetricTableCellDefaultComponent.vue';
import { ClassMetric } from '../../models/ClassMetrics';

// View Model
class TableViewModel {
  classMetric: ClassMetric;

  constructor(scheduleItem: ClassMetric) {
    this.classMetric = scheduleItem;
  }

  get highlight() {
    return false;
  }

  getCellComponent(property: string): Component {
    switch (property) {
      case 'avgGrade':
        return ClassMetricTableCellGradeComponent;
      case 'status':
        return ClassMetricTableCellStatusComponent;
      case 'dnif':
        return ClassMetricTableCellDnifComponent;
      default:
        return ClassMetricTableCellDefaultComponent;
    }
  }
}

export default defineComponent({
  props: {
    classMetrics: {
      type: Array as PropType<Array<ClassMetric>>,
      required: true
    }
  },
  setup(props) {
    const columnHeaders = computed(() => {
      return Object.keys(props.classMetrics[0]).map(
        (scheduleItemKey) => scheduleItemKey
      );
    });

    const tableData = computed(() => {
      return props.classMetrics.map(
        (classMetric) => new TableViewModel(classMetric)
      );
    });

    function formatColumnTitle(columName: string): string {
      return columName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
        return str.toUpperCase();
      });
    }

    return {
      columnHeaders,
      formatColumnTitle,
      tableData
    };
  }
});
