const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  darkGray: 'rgb(56,56,56)',
  white: 'rgb(255,255,255)',
  transparent: 'rgba(0,0,0,0)'
};

export const decelerateDataSet = {
  labels: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  datasets: [
    {
      label: 'yellow line',
      data: [-10, -14, -16, -15, -12, -8, -12, -11],
      borderColor: CHART_COLORS.yellow
    },
    {
      data: [50, -50],
      borderColor: CHART_COLORS.transparent
    },
    {
      data: [
        -10,
        -14,
        -13,
        -11,
        -3,
        -5,
        -8,
        -12,
        -14,
        -11,
        -15,
        -18,
        -15,
        -31,
        -28,
        -40,
        -43,
        -49,
        -50
      ],
      borderColor: CHART_COLORS.darkGray
    },
    {
      data: [
        -10,
        -14,
        -13,
        -11,
        -5,
        -8,
        -10,
        -13,
        -15,
        -12,
        -18,
        -15,
        -31,
        -28,
        -40,
        -42,
        -49,
        -50
      ],
      borderColor: CHART_COLORS.darkGray
    },
    {
      data: [
        -10,
        -11,
        -8,
        -11,
        -5,
        -6,
        -8,
        -13,
        -15,
        -7,
        -7,
        -15,
        -31,
        -28,
        -32,
        -40,
        -49,
        -50
      ],
      borderColor: CHART_COLORS.darkGray
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: CHART_COLORS.white
    }
  ]
};
