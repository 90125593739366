
import { computed, defineComponent, PropType } from 'vue';
import MetricBar from '@/components/MetricBarComponent.vue';
import { Student } from '@/models/Student';
import Class from '../../models/Class';

export default defineComponent({
  components: { MetricBar },
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    classData: {
      type: Object as PropType<Class>,
      required: true
    }
  },
  setup(props) {
    const progress = computed(() => {
      const studentProgress = props.student.getPhaseProgress();
      if (studentProgress) {
        const orderedProgress = {
          preflight: studentProgress.preflight,
          qualification: studentProgress.qualification,
          mission: studentProgress.mission,
          ...studentProgress
        };
        return orderedProgress;
      } else {
        return {
          preflight: { percentage: 0 },
          qualification: { percentage: 0 },
          mission: { percentage: 0 }
        };
      }
    });

    const formatPhaseLabel = (phase: string) => {
      return phase.charAt(0).toUpperCase() + phase.slice(1);
    };

    const courseLabel = props.classData.courseId
      ? props.classData.courseId
      : 'No course assigned';

    const trendDirection = computed(() => {
      const probability = props.student.graduationProbability;
      if (probability < 0.7) {
        return 'down';
      } else if (probability < 0.9) {
        return 'right';
      } else if (probability >= 0.9) {
        return 'up';
      } else {
        return 'none';
      }
    });
    return {
      progress,
      courseLabel,
      formatPhaseLabel,
      trendDirection
    };
  }
});
