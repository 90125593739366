
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const textColor = computed(() => {
      if (props.data > 1) {
        return 'text-yellow-500';
      }
      return 'text-green-500';
    });
    return {
      textColor
    };
  }
});
