import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-4 pr-6 text-xs" }
const _hoisted_2 = { class: "flex items-center mb-6" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "w-16 h-16 overflow-hidden border rounded-full" }
const _hoisted_5 = { class: "mx-4" }
const _hoisted_6 = { class: "text-gray-400" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-center w-10 h-10 transform rotate-45 bg-red-500 rounded-full"
}
const _hoisted_8 = {
  key: 1,
  class: "flex items-center justify-center w-10 h-10 transform -rotate-45 bg-green-500 rounded-full"
}
const _hoisted_9 = {
  key: 2,
  class: "flex items-center justify-center w-10 h-10 bg-yellow-500 rounded-full"
}
const _hoisted_10 = {
  key: 3,
  class: "flex items-center justify-center w-10 h-10 bg-gray-500 rounded-full"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "w-40 mr-2 text-xs uppercase whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")
  const _component_metric_bar = _resolveComponent("metric-bar")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("img", {
            src: _ctx.student.photoUrl,
            alt: _ctx.student.firstName
          }, null, 8, ["src", "alt"])
        ])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", {
          class: "text-sm font-semibold",
          "data-testid": `student-name-${_ctx.student.id}`
        }, _toDisplayString(_ctx.student.firstName) + " " + _toDisplayString(_ctx.student.lastName), 9, ["data-testid"]),
        _createVNode("div", _hoisted_6, _toDisplayString(_ctx.courseLabel), 1)
      ]),
      _createVNode("div", null, [
        (_ctx.trendDirection === 'down')
          ? (_openBlock(), _createBlock("div", _hoisted_7, [
              _createVNode(_component_fa, {
                icon: "arrow-right",
                type: "fas",
                height: "20"
              })
            ]))
          : (_ctx.trendDirection === 'up')
            ? (_openBlock(), _createBlock("div", _hoisted_8, [
                _createVNode(_component_fa, {
                  icon: "arrow-right",
                  type: "fas",
                  height: "20"
                })
              ]))
            : (_ctx.trendDirection === 'right')
              ? (_openBlock(), _createBlock("div", _hoisted_9, [
                  _createVNode(_component_fa, {
                    icon: "arrow-right",
                    type: "fas",
                    height: "20"
                  })
                ]))
              : (_openBlock(), _createBlock("div", _hoisted_10, [
                  _createVNode(_component_fa, {
                    icon: "times",
                    type: "fas",
                    height: "20"
                  })
                ]))
      ])
    ]),
    (_ctx.progress)
      ? (_openBlock(), _createBlock("div", _hoisted_11, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.progress, (phase, index) => {
            return (_openBlock(), _createBlock("div", {
              class: "flex items-center mb-2",
              key: index
            }, [
              _createVNode("div", _hoisted_12, _toDisplayString(_ctx.formatPhaseLabel(index)), 1),
              _createVNode(_component_metric_bar, {
                "data-testid": `student-metric-bar-${index}`,
                value: phase.percentage,
                showHighlight: true,
                class: "w-full"
              }, null, 8, ["data-testid", "value"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}