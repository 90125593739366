
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: 0
    },
    showHighlight: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  setup(props) {
    const barWidth = computed(() => {
      //TODO: SLP-755 Demo Patch Progress Bars hack
      return Math.min(Math.round((props.value / props.max) * 100), 100);
    });
    return { barWidth };
  }
});
