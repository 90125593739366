const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  darkGray: 'rgb(56,56,56)',
  white: 'rgb(255,255,255)',
  transparent: 'rgba(0,0,0,0)'
};

export const accelerateDataSet = {
  labels: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ],
  datasets: [
    {
      label: 'yellow line',
      data: [-28, -20, -2, 0, 4, 12, 20, 22, 23, 25],
      borderColor: CHART_COLORS.green
    },
    {
      data: [50, -50],
      borderColor: CHART_COLORS.transparent
    },
    {
      data: [
        -24,
        -9,
        -1,
        6,
        10,
        14,
        18,
        23,
        20,
        27,
        20,
        27,
        28,
        30,
        28,
        29,
        30,
        25,
        30,
        40
      ],
      borderColor: CHART_COLORS.darkGray
    },
    {
      data: [
        -24,
        -9,
        -1,
        5,
        8,
        7,
        12,
        13,
        17,
        20,
        27,
        20,
        24,
        30,
        32,
        27,
        28,
        34,
        33,
        40
      ],
      borderColor: CHART_COLORS.darkGray
    },
    {
      data: [
        -24,
        -9,
        -1,
        2,
        10,
        7,
        12,
        13,
        17,
        22,
        27,
        20,
        24,
        30,
        32,
        22,
        28,
        34,
        32,
        40
      ],
      borderColor: CHART_COLORS.darkGray
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: CHART_COLORS.white
    }
  ]
};
