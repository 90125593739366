
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { Chart, registerables, ChartData } from 'chart.js';
Chart.register(...registerables);

export default defineComponent({
  props: {
    chartData: {
      type: Object as PropType<ChartData>,
      required: true
    },
    studentId: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const myTrendingChart = ref(null);

    const studentData = props.chartData.datasets.find(
      (dataset) => dataset.label === props.studentId
    );

    const neighborData = props.chartData.datasets.filter(
      (dataset) => dataset.label !== props.studentId
    );

    const studentChartData = studentData
      ? {
          labels: studentData.data.map((_, index) => index),
          datasets: [
            {
              label: 'student',
              data: studentData.data,
              borderColor: 'rgb(75, 192, 192)'
            }
          ]
        }
      : {
          labels: [],
          datasets: []
        };

    const neighborChartData = neighborData
      ? {
          labels: neighborData[0].data.map((_, index) => index),
          datasets: neighborData.map((dataset) => ({
            label: dataset.label,
            data: dataset.data,
            borderColor: 'rgb(56,56,56)'
          }))
        }
      : {
          labels: [],
          datasets: []
        };

    const centerLine = {
      labels: [],
      datasets: [
        {
          label: 'center line',
          data: neighborChartData.datasets[0].data.map((_) => 0),
          borderColor: 'rgb(256, 256, 256)'
        }
      ]
    };
    const chartLabels = ref(props.chartData.labels);

    onMounted(() => {
      if (myTrendingChart.value !== null) {
        const canvas: any = myTrendingChart.value;
        if (!(canvas instanceof HTMLCanvasElement)) {
          return;
        }
        if (!canvas || !canvas.getContext) return;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) return;
        new Chart(ctx, {
          type: 'line',
          data: {
            labels: chartLabels.value,
            datasets: [
              ...centerLine.datasets,
              ...studentChartData.datasets,
              ...neighborChartData.datasets
            ]
          },
          options: {
            elements: {
              point: {
                radius: 0
              },
              line: {
                borderWidth: 2
              }
            },
            scales: {
              y: {
                display: false,
                grid: {
                  display: false
                }
              },
              x: {
                display: false,
                grid: {
                  display: false
                }
              }
            },
            plugins: {
              tooltip: {
                enabled: false
              },
              title: {
                display: false
              },
              legend: {
                display: false
              }
            }
          }
        });
      }
    });
    return {
      myTrendingChart
    };
  }
});
